'use strict';

// ----------------------------------------------------------------------------
// Sage JSHint globals
// ----------------------------------------------------------------------------
/* globals ease ga scrollReveal initLazysizes */

var app = {
  slickOpts: {
    prevArrow: '<div class="slick-nav slick-prev"></div>',
    nextArrow: '<div class="slick-nav slick-next"></div>'
  }
};

function successStories() {

  setTimeout(function() { 

  console.log( '{ successStories }' );

  // Set heights of the slider container to overlap image
  var sss = $('.success-stories-section');

  var sliderTitleHeight    = sss.find('.block-title').outerHeight();
  var sliderSubtitleHeight = sss.find('.block-subtitle').outerHeight();
  var sliderSlideHeight    = sss.find('.success-stories-slider').outerHeight();
  var sliderSecHeight      = (sliderTitleHeight + sliderSubtitleHeight + sliderSlideHeight + 90) - 200;

  if ($(window).width() >= 992) {
    // console.log( 'sliderSecHeight: %o', sliderSecHeight );

    sss.height( sliderSecHeight );
    sss.find('.success-stories-inner').height( sliderSecHeight - 21 );
  }

  $('.slider-image').removeClass('lazyloading').fadeIn();

  }, 2000);

}

// ************** SHAPE OVERLAYS **************
var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

// SVG Morph for page transition
var ShapeOverlays = function () {
  function ShapeOverlays(elm) {
    _classCallCheck(this, ShapeOverlays);

    this.elm = elm;
    this.path = elm.querySelectorAll('path');
    this.numPoints = 10;
    this.duration = 900;
    this.delayPointsArray = [];
    this.delayPointsMax = 300;
    this.delayPerPath = 250;
    this.timeStart = Date.now();
    this.isOpened = false;
    this.isAnimating = false;
  }

  _createClass(ShapeOverlays, [{
    key: 'toggle',
    value: function toggle() {
      this.isAnimating = true;
      for (var i = 0; i < this.numPoints; i++) {
        this.delayPointsArray[i] = Math.random() * this.delayPointsMax;
      }
      if (this.isOpened === false) {
        this.open();
      } else {
        this.close();
      }
    }
  }, {
    key: 'open',
    value: function open() {
      this.isOpened = true;
      this.elm.classList.add('is-opened');
      this.timeStart = Date.now();
      this.renderLoop();
    }
  }, {
    key: 'close',
    value: function close() {
      this.isOpened = false;
      this.elm.classList.remove('is-opened');
      this.timeStart = Date.now();
      this.renderLoop();
    }
  }, {
    key: 'updatePath',
    value: function updatePath(time) {
      var points = [];
      var i = 0;
      for (i; i < this.numPoints; i++) {
        points[i] = (1 - ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1))) * 100;
      }

      var str = '';
      str += this.isOpened ? 'M 0 0 V ' + points[0] : 'M 0 ' + points[0];
      for (i = 0; i < this.numPoints - 1; i++) {
        var p = (i + 1) / (this.numPoints - 1) * 100;
        var cp = p - 1 / (this.numPoints - 1) * 100 / 2;
        str += 'C ' + cp + ' ' + points[i] + ' ' + cp + ' ' + points[i + 1] + ' ' + p + ' ' + points[i + 1] + ' ';
      }
      str += this.isOpened ? 'V 100 H 0' : 'V 0 H 0';
      return str;
    }
  }, {
    key: 'render',
    value: function render() {
      var l = this.path.length;
      var i = 0;
      if (this.isOpened) {
        for (i; i < l; i++) {
          this.path[i].setAttribute('d', this.updatePath(Date.now() - (this.timeStart + this.delayPerPath * i)));
        }
      } else {
        for (i; i < l; i++) {
          this.path[i].setAttribute('d', this.updatePath(Date.now() - (this.timeStart + this.delayPerPath * (this.path.length - i - 1))));
        }
      }
    }
  }, {
    key: 'renderLoop',
    value: function renderLoop() {
      var _this = this;

      this.render();
      if (Date.now() - this.timeStart < this.duration + this.delayPerPath * (this.path.length - 1) + this.delayPointsMax) {
        requestAnimationFrame(function () {
          _this.renderLoop();
        });
      } else {
        this.isAnimating = false;
      }
    }
  }]);

  return ShapeOverlays;
}();


// ************** Initialize Contact Form 7 *************
// function initContactForm() {
//   $('div.wpcf7 > form').each(function () {
//     var $form = $(this);
//     wpcf7.initForm($form);
//     if (wpcf7.cached) {
//       wpcf7.refill($form);
//     }
//   });
// }


// ************** OOZE EFFECT *************

var Curves = (function () {

  var raf_ID = 0;

  function Shape(points, color) {
    this.points = points;
    this.color = color;
  }

  Shape.prototype.render = function (ctx, width, height) {
    var self = this;
    ctx.save();
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#fff';
    ctx.fillStyle = 'rgba(255, 255, 255, 0)';
    ctx.fillStyle = this.color;
    ctx.beginPath();
    ctx.moveTo(this.points[0].x, this.points[0].y);
    this.points.forEach(function (point, i) {
      point.y = point.oldY + Math.sin(point.angle) * 35;
      point.angle += point.speed;
      var nextPoint = self.points[i + 1];
      if (nextPoint) {
        var ctrlPoint = {
          x: (point.x + nextPoint.x) / 2,
          y: (point.y + nextPoint.y) / 2
        };
        ctx.quadraticCurveTo(point.x, point.y, ctrlPoint.x, ctrlPoint.y);
      }
    });
    ctx.lineTo(width, height);
    ctx.lineTo(0, height);
    ctx.fill();

    ctx.restore();
  };

  if ($("#ooze").length) {
    var canvas = document.getElementById('ooze');
    var ctx = canvas.getContext('2d');
  }

  var width = window.innerWidth;
  var height = window.innerHeight;

  var colors = [
    '#2f3030', '#5e6060', '#ecf0f1', '#8d9090', '#bcc0c0', '#ecf0f1'
  ];
  // var position = {
  //   x: 0,
  //   y: height / 1.5
  // };
  var shapes = generateShapes(3, height / 1.5, width / 20);

  function generateShapes(num, yCenter, spacing) {
    var shapes = [];
    for (var i = 0; i < num; i += 1) {
      var points = [];
      var offset = 0;
      for (var x = 0; x <= width + width / 4; x += spacing) {
        var angle = Math.random() * 360;
        if (i === 0) offset = 20 + Math.random() * 40 - 50;
        if (i === 1) offset = 80 + Math.random() * 60 - 50;
        if (i === 2) offset = 110 + Math.random() * 80 - 50;
        if (i === 3) offset = 150 + Math.random() * 100 - 50;
        if (i === 4) offset = 200 + Math.random() * 130 - 50;
        if (i === 5) offset = 250 + Math.random() * 170 - 50;
        offset -= x / 20;
        var point = {
          x: x,
          y: yCenter + offset + 10 + Math.random() * 20,
          oldY: yCenter + offset,
          angle: angle,
          speed: 0.015
        };
        points.push(point);
      }
      var shape = new Shape(points, colors[i]);
      shapes.push(shape);
    }
    //console.log(shapes)
    return shapes;
  }

  function init(parent) {

    canvas.width = width;
    canvas.height = height;
    parent.appendChild(canvas);
    ctx.fillStyle = '#000000';
    startRender();
    window.onresize = function () {
      resize();
    };
  }

  function render() {
    raf_ID = window.requestAnimationFrame(render);
    ctx.fillRect(0, 0, width, height);
    shapes.forEach(function (shape) {
      shape.render(ctx, width, height);
    });
  }

  function startRender() {
    render();
  }

  function stopRender() {
    window.cancelAnimationFrame(raf_ID);
  }

  function resize() {
    canvas.width = width = window.innerWidth;
    canvas.height = height = window.innerHeight;
  }

  return {
    init: init,
    startRender: startRender,
    stopRender: stopRender
  };

})();

function ooze() {

  // Curves.init(document.getElementById('main'));
  Curves.init(document.body);
}


// window.onload = function () {
//   console.log( 'window.onload' );
//   if ($("#ooze").length) {
//     console.log( 'aaa Curves: %o', Curves );
//     Curves.stopRender();
//     ooze();
//   }
// };

// ************** Hide All Modals **************

function hideModals() {
  $('#package_1_modal').modal('hide');
  $('#package_2_modal').modal('hide');
  $('#package_2_modal').modal('hide');
}

// ************** SMOOTH STATE **************

$(function () {
  var options = {
      prefetch: true,
      cacheLength: 4,
      blacklist: 'form, .no-smoothState',
      onStart: {
        duration: 1500, // Duration of our animation
        render: function ($container) {
          $('.cbp-spmenu').removeClass('cbp-spmenu-open');
          $('.shape-overlays').css('display', 'block').fadeIn("fast");
          (function () {
            var elmOverlay = document.querySelector('.shape-overlays');
            var overlay = new ShapeOverlays(elmOverlay);
            overlay.toggle();
          })();
          // Hide any modals that might be open
          hideModals();
          // Add your CSS animation reversing class
          $container.addClass('is-exiting');
        }
      },
      onReady: {
        duration: 0,
        render: function ($container, $newContent) {
          // Remove your CSS animation reversing class
          $container.removeClass('is-exiting');
          // Inject the new content
          $container.html($newContent);
        }
      },
      onAfter: function () {
      // onAfter: function ($container, $newContent) {
        // console.log( 'smoothState onAfter' );
        prepOnLoad();
        prep();
        // console.log( 'onAfter: Curves: %o', Curves );
        if ($("#ooze").length) {
          Curves.stopRender();
          // ooze();
          Curves.startRender();
        }
        $('.shape-overlays').fadeOut("slow").delay(1000).css('display', 'none)');
        //initContactForm();
        dataLayer.push({
          'event': 'virtualPageView',
          'virtualUrl': location.pathname,
          'virtualTitle': document.title
        });
        $(window).trigger('resize');
      }
    },
    smoothState = $('#main').smoothState(options).data('smoothState');
});


function openWindow(url) {

  if (window.innerWidth <= 640) {
    // if width is smaller then 640px, create a temporary a elm that will open the link in new tab
    var a = document.createElement('a');
    a.setAttribute("href", url);
    a.setAttribute("target", "_blank");

    var dispatch = document.createEvent("HTMLEvents");
    dispatch.initEvent("click", true, true);

    a.dispatchEvent(dispatch);
  } else {
    var width = window.innerWidth * 0.66;
    // define the height in
    var height = width * window.innerHeight / window.innerWidth;
    // Ratio the hight to the width as the user screen ratio
    window.open(url, 'newwindow', 'width=' + width + ', height=' + height + ', top=' + ((window.innerHeight - height) / 2) + ', left=' + ((window.innerWidth - width) / 2));
  }
  return false;
}

// ************** Function for All Page Ready Scripts **************
function prepOnLoad() {

  // console.log( 'prep: onload' );
  var sss = $('.success-stories-slider');
  var rs  = $('.resources-slider');

  // Testimonials Slider

  if($('.testimonials-slider').length) {
    $('.testimonials-slider').slick(app.slickOpts);
  }

  // Stories Slider
  if($('.stories-slider').length) {
    $('.stories-slider').slick(app.slickOpts);
  }

  if ( rs.length ) {
    // Resources slider
    rs.imagesLoaded( function() {
      // images have loaded
      rs.slick(app.slickOpts);
    });
  }


  if ( sss.length ) {
    // Success Stories Slider
    sss.imagesLoaded( function() {
      // On slick init
      sss.on('init', function(){
        successStories();
      });
      // images have loaded
      sss.slick(app.slickOpts);
    });
  }
  lazySizes.autoSizer.checkElems();
}

function prep() {

  //update this value if you change this breakpoint in the style.css file (or _layout.scss if you use SASS)
  var MQ = 1170;
  // Intro Effect
  var introSection = $('#cd-intro-tagline');
  var introSectionHeight = introSection.height();
  //change scaleSpeed if you want to change the speed of the scale effect
  var scaleSpeed = 0.3;
  //change opacitySpeed if you want to change the speed of opacity reduction effect
  var opacitySpeed = 1;

  // Hamburger Menu
  var McButton = $("[data=hamburger-menu]");
  var McBar1 = McButton.find("b:nth-child(1)");
  // var McBar2 = McButton.find("b:nth-child(2)");
  var McBar3 = McButton.find("b:nth-child(3)");

  // Get the screen height and set the into at 100%
  var screenheight = $(window).height();
  // Stories Filters
  var storiesSlider = $('.stories-slider');


  // LazySizes init for AJAX
  // initLazysizes();
  // lazySizes.init();


  // Event for Contact Form submission
  document.addEventListener('wpcf7mailsent', function () {
    ga('send', 'event', 'Contact Form', 'submit', 'success');
  }, false);


  // Scroll Reveal Init for Scroll Animations
  window.sr = new scrollReveal();


  // Function to center elements on page
  jQuery.fn.center = function () {
    this.css("position", "absolute");
    this.css("top", Math.max(0, (($(window).height() - $(this).outerHeight()) / 2) +
      $(window).scrollTop()) + "px");
    this.css("left", Math.max(0, (($(window).width() - $(this).outerWidth()) / 2) +
      $(window).scrollLeft()) + "px");
    return this;
  };


  //assign a scale transformation to the introSection element and reduce its opacity
  function animateIntro() {
    var scrollPercentage = ($(window).scrollTop() / introSectionHeight).toFixed(5),
      scaleValue = 1 - scrollPercentage * scaleSpeed;
    //check if the introSection is still visible
    if ($(window).scrollTop() < introSectionHeight) {
      introSection.css({
        '-moz-transform': 'scale(' + scaleValue + ') translateZ(0)',
        '-webkit-transform': 'scale(' + scaleValue + ') translateZ(0)',
        '-ms-transform': 'scale(' + scaleValue + ') translateZ(0)',
        '-o-transform': 'scale(' + scaleValue + ') translateZ(0)',
        'transform': 'scale(' + scaleValue + ') translateZ(0)',
        'opacity': 1 - scrollPercentage * opacitySpeed
      });
    }
  }


  //bind the scale event to window scroll if window width > $MQ (unbind it otherwise)
  function triggerAnimation() {
    if ($(window).width() >= MQ) {
      $(window).on('scroll', function () {
        //The window.requestAnimationFrame() method tells the browser that you wish to perform an animation- the browser can optimize it so animations will be smoother
        window.requestAnimationFrame(animateIntro);
      });
    } else {
      $(window).off('scroll');
    }
  }


  $(document).ready(function () {

    // Slab Text
    $(".page-head").slabText({
      // Stop making crazy huge 2 line headers
      "fontRatio": 0.3
    });

    // Center some elements!
    // Don't center on mobile
    $(".inner-head-title").slabText({
      // Stop making crazy huge 2 line headers
      "fontRatio": 0.3
    });

    //if ($(window).width() > 600) {
    //  $('.contact-inner').center();
    //}

    // Instert the // between sub nab elements
    $('.menu-item-has-children').find('.sub-menu li:not(:last)').after('<li class="menu-item sep">//</li>');

    // Open/Close Top Menu
    $('#showTop').click(function () {
      $('#cbp-spmenu-s3').toggleClass('cbp-spmenu-open');
    });


    triggerAnimation();

  });


  McButton.click(function () {
    $(this).toggleClass("active");
    if (McButton.hasClass("active")) {
      menuOpen();
    } else {
      menuReverse();
    }
  });

  function menuOpen() {
    McBar1.velocity({
      top: "50%"
    }, {
      duration: 200,
      easing: "swing"
    });
    McBar3.velocity({
        top: "50%"
      }, {
        duration: 200,
        easing: "swing"
      })
      .velocity({
        rotateZ: "90deg"
      }, {
        duration: 800,
        delay: 200,
        easing: [500, 20]
      });
    McButton.velocity({
      rotateZ: "135deg"
    }, {
      duration: 800,
      delay: 200,
      easing: [500, 20]
    });
  }

  function menuReverse() {
    McButton.velocity("reverse");
    McBar3.velocity({
        rotateZ: "0deg"
      }, {
        duration: 800,
        easing: [500, 20]
      })
      .velocity({
        top: "100%"
      }, {
        duration: 200,
        easing: "swing"
      });
    McBar1.velocity("reverse", {
      delay: 800
    });
  }


  $('.header-section').css('height', screenheight);

  if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
    $(window).on("load", function () {
      prepOnLoad();
    });
  } else {
    window.onload = function () {
      //$(window).on("load", function () {
        prepOnLoad();
      };
  }


  $('.filter-seo').on('click', function () {
    storiesSlider.slick('slickUnfilter');
    storiesSlider.slick('slickFilter', '.seo-story');
  });

  $('.filter-ppc').on('click', function () {
    storiesSlider.slick('slickUnfilter');
    storiesSlider.slick('slickFilter', '.ppc-story');
  });

  $('.filter-web').on('click', function () {
    storiesSlider.slick('slickUnfilter');
    storiesSlider.slick('slickFilter', '.web-story');
  });

  $('.filter-consultation').on('click', function () {
    storiesSlider.slick('slickUnfilter');
    storiesSlider.slick('slickFilter', '.consultation-story');
  });

  $('.filter-all').on('click', function () {
    storiesSlider.slick('slickUnfilter');
  });


  // Insert the title of the parent item into the sub menu
  $('.menu-item-has-children').each(function () {
    // Grab the parent item name
    var parentitemname = $($(this).contents().get(0)).text();
    $(this).find('.sub-menu').after('<span class="sub-menu-title">' + parentitemname + '</span>');
    // Insert parent item name above sub menu
  });


  // Vertically centre sub menu within the nav
  $(window).on('resize', function () {

    if ( $('.success-stories-slider').length ) {
      successStories();
    }
    triggerAnimation();

    if ($(window).width() >= 768) {
      $('.page-template-contact').css('min-height', screenheight);
    }
    if ($(window).width() <= 767) {
      // Get the screen height and set the into at 100%
      $('.case-study-header').css('height', 'auto');
    }

    if ($(window).width() >= 769) {
      // Hide/show navigation sub items when hover over parent
      $('.menu-item-has-children').mouseenter(function () {
        // var activeitem = $(this);
        $(this).find('a').css('visibility', 'hidden');
        $(this).find('.menu-item').css('opacity', '1');
        $(this).find('.sub-menu-title').css('opacity', '1').css('visibility', 'visible');
        $(this).find('.menu-item a').css('visibility', 'visible');
        $(this).find('.sep').css('visibility', 'visible');
      });
      $('.menu-item-has-children').mouseleave(function () {
        $(this).find('a').css('visibility', 'visible');
        $(this).find('.menu-item').css('opacity', '0');
        $(this).find('.sub-menu-title').css('opacity', '0').css('visibility', 'hidden');
        $(this).find('.menu-item a').css('visibility', 'hidden');
        $(this).find('.sep').css('visibility', 'hidden');
      });
    } // end media query

    var menuitemheight = $('.menu-item a').height();
    //if (window.console) console.log(menuitemheight);
    var subitemheight = $('.sub-menu .menu-item').outerHeight();
    //if (window.console) console.log(subitemheight);
    var subtitleheight = $('.sub-menu-title').outerHeight();
    //if (window.console) console.log(subtitleheight);
    var totalmargin = menuitemheight - subitemheight - subtitleheight;
    //if (window.console) console.log(totalmargin);
    var availablemargin = totalmargin / 3;
    //if (window.console) console.log(availablemargin);
    var topmargin = (availablemargin * 2) + subitemheight;
    //if (window.console) console.log(topmargin);

    $('.sub-menu-title').css("top", availablemargin);
    $('.sub-menu .menu-item').css("margin-top", topmargin);
  });


  // Opens links with target=_new in new small window but in new tab on mobile
  $('a[target^="_new"]').click(function () {
    return openWindow(this.href);
  });

  // Hover state for service cards
  $('.service-card .yellow-btn')
    .mouseover(function() {
      var serviceCard = $(this).closest('.service-card');
      serviceCard.addClass('card-hovered');

      serviceCard.find('.service-card-icon').css({filter: 'invert(1)'});
    })
    .mouseout(function() {
      var serviceCard = $(this).closest('.service-card');
      serviceCard.removeClass('card-hovered');

      serviceCard.find('.service-card-icon').css({filter: 'invert(0)'});
    });

  // Make package table columns 1 and 3 the same height
  var middle = $('.mid-package');

  var leftHeight = $('.left-package').height();
  var middleHeight = middle.height();
  var rightHeight = $('.right-package').height();

  var tallerHeight = Math.max(leftHeight, rightHeight);
  var smallerHeight = Math.min(leftHeight, rightHeight);

  if (tallerHeight !== smallerHeight) {
    var isLeftColumnTaller = tallerHeight === leftHeight;

    $('.' + (isLeftColumnTaller ? 'right' : 'left') + '-package')
      .find('.block-text')
      .css({
        'padding-bottom': (tallerHeight - smallerHeight) + 'px',
      });
  }

  if (tallerHeight > middleHeight - 50) {
    var diff = (tallerHeight - middleHeight) + 50;
    middle.find('.block-text').css({
      'padding-bottom': diff + 'px',
    });
  }

} // End Prep


// Scripts to Fire on Ready / Refresh
$(document).ready(function () {
  prepOnLoad();
  prep();
  $(window).trigger('resize');
});


// When landed on page (not via ajax)
$(window).on("load", function () {
  // console.log( 'root: onload' );

  // Both ready and loaded
  $.ready.then(function () {
    // Fade in effect
    $('html').css('opacity', '1');
  });

  if ($("#ooze").length) {
    // console.log( 'aaa Curves: %o', Curves );
    Curves.stopRender();
    ooze();
  }
});

// Console Message
console.log('Silence is Golden 🏆');
